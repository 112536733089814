import React from "react";
import { graphql ,Link} from "gatsby";
import Layout from "../../../components/Layout";
import BackgroundImage from "gatsby-background-image";
import moment from "moment";
import { BiTimeFive} from "@react-icons/all-files/bi/BiTimeFive";


const Blog = ({ data }) => {
  let daa = data.allStrapiArticles.edges;
  return (
    <Layout title={"Blog"}>
      <div className="blog-main">
        <h1>ब्लॉग</h1>

        <div className="blog-container">
         
        {daa.filter(x=>x.node.hindiTitle).map((v,k)=><Link className="blog-card" to={"/blog/hindi/"+v.node.slug}>
         <div style={{minHeight:v.node.hindiTitle?.length>27?270:245}}>
            <BackgroundImage
              fluid={v.node.image.localFile.childImageSharp.fluid}
              className="blog-bg"
            />
            <div className="blog-ti" >
                {v.node.hindiTitle}
               {/* {("Lorem ipsum dolor sit, amet").length} */}
            </div>

            <p className="date-pp"><BiTimeFive/> {moment(v.node.createdAt).format("DD MMM YYYY")} — {v.node.category.name}</p>
            <p className="cat-pp">{}</p>

          </div>
          </Link>)}

       
        </div>
      </div>
    </Layout>
  );
};

export const query = graphql`
  {
    allStrapiArticles(sort:{fields:createdAt, order: DESC}) {
      edges {
        node {
          slug
          title
          hindiTitle
          createdAt
          category {
            name
          }
          image {
            localFile {
              childImageSharp {
                fluid(maxWidth: 300) {
                    ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
      totalCount
    }
  }
`;

export default Blog;
